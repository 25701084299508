import React, { FC } from 'react'
import NextHead from 'next/head'

interface Props {
	title?: string
	description?: string
	url?: string
	ogImage?: string
	favicon?: string
}

const Head: FC<Props> = ({ title = '', description = '', url = '', ogImage = '', favicon }) => (
	<NextHead>
		<meta charSet={'UTF-8'} />
		<title>{`Rustoria™ ${title ? `- ${title}` : ''}`}</title>
		<meta name={'description'} content={description} />
		<meta name={'viewport'} content={'width=device-width, initial-scale=1'} />
		<link rel={'icon'} sizes={'192x192'} href={favicon ? `/img/${favicon}` : '/img/main-favicon.png'} />
		<link rel={'apple-touch-icon'} href={favicon ? `/img/${favicon}` : '/img/main-favicon.png'} />
		<link rel={'icon'} href={favicon ? `/img/${favicon}` : '/img/main-favicon.png'} />
		<link
			href={'https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap'}
			rel={'stylesheet'}
		/>
		<link
			href={'https://fonts.googleapis.com/css2?family=Dosis:wght@400;600;700&display=swap'}
			rel={'stylesheet'}
		/>
		<link
			href={'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'}
			rel={'stylesheet'}
		/>
		<link
			href={'https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap'}
			rel={'stylesheet'}
		/>
		<meta property={'og:url'} content={url} />
		<meta property={'og:title'} content={`Rustoria™ ${title ? `- ${title}` : ''}`} />
		<meta property={'og:description'} content={description} />
		<meta name={'twitter:site'} content={url} />
		<meta name={'twitter:card'} content={'summary_large_image'} />
		{ogImage && <meta name={'twitter:image'} content={`https://staging.rustoria.co/img/${ogImage}`} />}
		{ogImage && <meta property={'og:image'} content={`https://staging.rustoria.co/img/${ogImage}`} />}
		<meta property={'og:image:width'} content={'400'} />
		<meta property={'og:image:height'} content={'200'} />
		<script src='https://unpkg.com/imagesloaded@4/imagesloaded.pkgd.min.js'></script>
		<script src='https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js'></script>
		<link
			rel={'stylesheet'}
			href={'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css'}
			integrity={
				'sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA=='
			}
			crossOrigin={'anonymous'}
		/>
		<link
			rel='stylesheet'
			type='text/css'
			charSet='UTF-8'
			href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
		/>
		<link
			rel='stylesheet'
			type='text/css'
			href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
		/>
	</NextHead>
)

export default Head
