import { DefaultTheme } from 'styled-components'

export const getTheme = (theme: 'light' | 'dark'): DefaultTheme => {
	// Names are messy for now. Would like
	// to have them be tone-based, but with
	// how the app is structured, this works best.

	const lightColors = {
		body: 'white',
		bgDark: '#161616',
		bgPrimary: '#f8f8f8',
		bgSecondary: '#f2f2f2',
		bgTertiary: '#e7e7e7',
		bgQuaternary: '#e0e0e0',
		accentPrimary: '#c2c2c2',
		accentSecondary: '#d7d7d7',
		textPrimary: 'black',
		textSecondary: '#767676',
		textTertiary: '#454545',
	}

	const darkColors = {
		body: '#141519',
		bgDark: '#161616',
		bgPrimary: '#1b1c21',
		bgSecondary: '#37383d',
		bgTertiary: '#2b2c32',
		bgQuaternary: '#26272d',
		accentPrimary: '#4b4650',
		accentSecondary: '#2f333c',
		textPrimary: 'white',
		textSecondary: '#878080',
		textTertiary: '#a39e9e',
	}
	
	const primaryColors = {
		primaryGreen: '#18db69',
		secondaryGreen: '#4da471',
		primaryRed: '#ff6666',
		secondaryRed: '#e64040',
		primaryBlue: '#2994f2',
		secondaryBlue: '#1675f0',
		primaryOrange: '#E0BD18',
		primaryYellow: '#eaed87',
		secondaryYellow: '#e7f048',
	}

	return {
		current: theme,
		breakpoints: ['576px', '768px', '992px', '1200px', '1920px'],
		fonts: {
			body: '"Open Sans", Arial, sans-serif',
			link: 'Quicksand, sans-serif',
			heading: 'Dosis, sans-serif',
			brand: 'Bebas Neue, sans-serif',
		},
		colors: theme === 'light' ? { ...lightColors, ...primaryColors } : { ...darkColors, ...primaryColors },
		serviceColors: {
			steam: {
				primary: '#101823',
				secondary: '#151F2D',
			}, 
			discord: {
				primary: '#5865F2',
				secondary: '#6974f3',
			}, 
			twitter: {
				primary: '#1DA1F3',
				secondary: '#43B1F5',
			}, 
			youtube: {
				primary: '#B71D1C',
				secondary: '#BD5353',
			}, 
			twitch: {
				primary: '#5D3D99',
				secondary: '#6441A4',
			}, 
		}
	}
}
