import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
	html, body, body > div {
		height: 100%;
		background: ${({ theme }) => theme.colors.body};
		color: ${({ theme }) => theme.colors.textPrimary};
	}

	* {
		box-sizing: border-box;
	}

	body {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		overflow-x: hidden;
		font-family: ${({ theme }) => theme.fonts.body};
	}

	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-track {
		background-color: ${({ theme }) => theme.colors.bgTertiary};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => (theme.current === 'dark' ? '#42434a' : 'rgb(30, 30, 30)')};
	}

	a, input, button, textarea {
		background: none;
		color: inherit;
		border: none;
		padding: 0;
		margin: 0;
		font: inherit;
		outline: inherit;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}

	a, button {
		cursor: pointer;
	}
	
	input {
		cursor: text;
	}

	.fa, .fas {
		font-weight: 900;
	}

	.fa, .far, .fas {
		font-family: "Font Awesome 5 Free";
	}

	.fa, .fab, .fal, .far, .fas {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
	}

	.highlight {
		border: 1px solid ${({ theme }) => theme.colors.primaryBlue} !important;
		position: relative;
		transition: all 0.25s;
		z-index: 999;

		-webkit-box-shadow:0px 0px 80px 0px rgba(41,148,242,0.65);
		-moz-box-shadow: 0px 0px 80px 0px rgba(41,148,242,0.65);
		box-shadow: 0px 0px 80px 0px rgba(41,148,242,0.65);
	}

	.no-glow {
		box-shadow: none;
		border: none !important;
	}

	.fa-home::before {
		content: "\f015";
	}

	.slick-next::before, .slick-prev::before {
		color: black;
	}

	.weglot-container * {
		background-color: ${({ theme }) => theme.colors.body} !important;
		font-family: ${({ theme }) => theme.fonts.body};
		color: ${({ theme }) => theme.colors.textPrimary} !important;
		border-color: ${({ theme }) => theme.colors.accentSecondary} !important;
		border-bottom: none !important;
	}

	.wgcurrent:after {
		filter: invert(1);
	}

	@keyframes fadePushUp {
		from {
			opacity: 0;
			transform: translateY(50px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

	@keyframes progress {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }
`
