import { Dispatch, FC, useEffect, useState } from 'react';
import { PopupButton, Wrapper } from './styles';
import { Link, Span } from '@components/common';
import NextLink from 'next/link';

interface CookiePopupProps {
	cookiesAccepted: boolean;
	setCookiesAccepted: Dispatch<boolean>;
}

const CookiePopup: FC<CookiePopupProps> = ({ cookiesAccepted, setCookiesAccepted }) => {
	const [awaitingMount, setAwaitingMount] = useState<boolean>(true);

	// Ensures that the transition occurs when the user can see it
	useEffect(() => {
		setTimeout(() => setAwaitingMount(false), 1000);
	}, []);

	return (
		<Wrapper visable={!cookiesAccepted && !awaitingMount}>
			<Span color={'white'}>
				By using our website you agree to the&nbsp;
				<NextLink href={'/privacy'} passHref>
					<Link
						color={'primaryBlue'}
						fontWeight={700}
						textDecoration={'none'}
						hoverTextDecoration={'underline'}
					>
						Privacy Policy
					</Link>
				</NextLink>
				&nbsp;and&nbsp;
				<NextLink href={'/terms'} passHref>
					<Link
						color={'primaryBlue'}
						fontWeight={700}
						textDecoration={'none'}
						hoverTextDecoration={'underline'}
					>
						Terms and Conditions
					</Link>
				</NextLink>
				.
			</Span>
			<PopupButton onClick={() => setCookiesAccepted(true)}>Understood</PopupButton>
		</Wrapper>
	);
};

export default CookiePopup;
