import { FC } from 'react';
import { PopupButton, Wrapper } from './styles';
import { Span } from '@components/common';

interface ChangesPopupProps {
	visable: boolean;
	disabled?: boolean;
	onCancel: () => void;
	onSave: () => void;
}

const ChangesPopup: FC<ChangesPopupProps> = ({ visable, onCancel, onSave, disabled }) => {
	return (
		<Wrapper visable={visable}>
			<Span color={'white'}>Hey! You have unsaved changes.</Span>
			<div>
				<PopupButton onClick={onCancel} disabled={disabled}>
					Cancel
				</PopupButton>
				<PopupButton
					backgroundColor={'primaryBlue'}
					hoverBackgroundColor={'secondaryBlue'}
					onClick={onSave}
					disabled={disabled}
					margin
				>
					Save
				</PopupButton>
			</div>
		</Wrapper>
	);
};

export default ChangesPopup;
