import styled from 'styled-components';

interface WrapperProps {
	visable: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
	position: fixed;
	bottom: 15px;
	left: 50%;
	width: 60%;
	padding: 15px 20px;
	z-index: 1000;

	background-color: rgba(10, 10, 10, 0.95);
	border-radius: 5px;
	box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.5);

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	transition: 0.5s all;
	transform: ${({ visable }) => (visable ? 'translateX(-50%)' : 'translateX(-50%) translateY(150px)')};

	@media (max-width: 1290px) {
		width: 80%;
	}

	@media (max-width: 980px) {
		width: 95%;
	}

	@media (max-width: 550px) {
		flex-direction: column;
		text-align: center;

		> span {
			line-height: 1.2;
		}

		> :last-child {
			margin-top: 10px;
		}
	}
`;

interface PopupButtonProps {
	backgroundColor?: string;
	hoverBackgroundColor?: string;
	margin?: boolean;
}

export const PopupButton = styled.button<PopupButtonProps>`
	background-color: ${({ theme, backgroundColor }) =>
		backgroundColor ? theme.colors[backgroundColor] : '#262424'};
	border-radius: 2px;
	color: white;
	font-family: ${({ theme }) => theme.fonts.link};
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 8px 14px;
	transition: 0.25s all;
	${({ margin }) => margin && 'margin: 0 10px;'}

	:hover {
		background-color: ${({ theme, hoverBackgroundColor }) =>
			hoverBackgroundColor ? theme.colors[hoverBackgroundColor] : '#342e2e'};
	}

	:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;
