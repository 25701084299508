import { FC, useEffect, useState } from 'react'
import { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from 'react-query'

import { GlobalStyle, getTheme } from '../style'
import { AuthContextProvider, useAuthContext } from '@context'
import { useLocalStorage } from '@hooks'
import { CookiePopup } from '@components/Popups';
import Head from '@components/Head'
import { ApiService, CookieService } from '@services'
import { errorToast } from '@components/common'
import toast, { Toaster } from 'react-hot-toast'
import { useRouter } from 'next/router'

const queryClient = new QueryClient()

const App = ({ Component, pageProps }: AppProps) => {
	const [cookiesAccepted, setCookiesAccepted] = useLocalStorage<boolean>('cookiesAccepted', false)
	const [themeValue] = useLocalStorage<'light' | 'dark'>('theme', 'dark')
	const theme = getTheme(themeValue);
	const router = useRouter();

	const [isMounted, setIsMounted] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsMounted(true);
		router.events.on('routeChangeStart', () => setIsLoading(true));
		router.events.on('routeChangeComplete', () => setIsLoading(false));
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<AuthContextProvider>
				<ThemeProvider theme={theme}>
					<AuthManager/>
					<GlobalStyle />
					<Toaster
						toastOptions={{
							position: 'top-right',
							style: {
								backgroundColor: theme.colors.bgPrimary,
								border: `1px solid ${theme.colors.accentSecondary}`,
								color: theme.colors.textPrimary,
								borderRadius: '6px',
							},
							success: {
								style: {
									color: theme.colors.primaryGreen,
								},
							},
							loading: {
								style: {
									color: theme.colors.textSecondary,
								}
							},
						}}
						containerStyle={{
							zIndex: 99999,
						}}
					/>
					{/* <Snowfall
						snowflakeCount={50}
						style={{
							zIndex: router.route === '/' ? 99 : 1,
							position: 'fixed',
							height: '100vh',
							width: '100vw',
						}}
					/> */}
					{isLoading && (
						<div style={{
							animation: 'progress 1s',
							backgroundColor: theme.colors.primaryBlue,
							height: '2.5px',
							width: '100%',
							position: 'fixed',
							zIndex: 2000,
						}}/>
					)}
					{isMounted && <Component {...pageProps} />}
					<Head
						title={pageProps?.head?.title}
						description={pageProps?.head?.description}
						ogImage={pageProps?.head?.ogImage}
						favicon={pageProps?.head?.favicon}
					/>
					<CookiePopup cookiesAccepted={cookiesAccepted} setCookiesAccepted={setCookiesAccepted} />
				</ThemeProvider>
			</AuthContextProvider>
		</QueryClientProvider>
	)
}

const AuthManager: FC = () => {
	const { setUser } = useAuthContext();
	const router = useRouter();

	useEffect(() => {
		if (router.isReady) {
			const params = new URLSearchParams(router.query as {[key: string]: string});
			if (router.query.token) {
				const toastId = toast.loading('Logging in', { position: 'top-left' });
				CookieService.setAccessToken(router.query.token as string);

				params.delete('token');
				router.replace({
					pathname: router.pathname,
					query: params.toString(),
				}, undefined, { shallow: true });

				ApiService.getUserProfile().then((userProfile) => {
					toast.success(`Logged in as ${userProfile.username}`, { id: toastId });
					setUser(userProfile);
				}).catch(() => errorToast('Failed to login', toastId));
			}

			if (router.query.loggedOut) {
				params.delete('loggedOut');
				router.replace({
					pathname: router.pathname,
					query: params.toString(),
				}, undefined, { shallow: true });

				setTimeout(() => toast.success('Logged out', { position: 'top-left' }), 500);
			}

			ApiService.getUserProfile().then((user) => {
				setUser(user);
			});
		}
	}, [router.isReady]);

	return <div/>;
}

export default App
